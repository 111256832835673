body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


 div#nav-menu-item-29788 a, a.header-btn {
  padding: 6px 20px;
  background: #00A5BF!important;
  color: #ffffff!important;
  position: absolute;
    right: 60px;
    top:63px;
  border-radius: 6px;
  text-decoration: none;
  font-family: 'Hind', sans-serif;
    font-size: 15px!important;
  
 }

 h3 {

  text-transform: capitalize;
  font-weight: 500!important;
  font-size: 38px !important;
  color: #5b5b5b!important;
  font-family: 'Hind', 'Arial', sans-serif!important;
  /* text-rendering: optimizelegibility; */
  /* position: absolute; */
  top: 36px;
  /* left: 500px; */
  margin: -101px;
}



header a.navbar-brand img{    max-height: 99px;
  margin: 14px;}



  body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
  }
  
  #myBtn {
    display: none;
    position: fixed;
    bottom: 26px;
    right: 40px;
    z-index: 99;
    font-size: 16px;
    border: none;
    outline: none;
    background-color: rgba(124, 0, 0, 0.781);
    color: white;
    cursor: pointer;
    padding: 9px;
    border-radius: 20px;
  }
  
  #myBtn:hover {
    background-color: rgba(204, 0, 0, 0.781)
  }




