

th {
  font-family: 'Hind', 'Arial', 'sans-serif';
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  /* text-align: center; */
  /* column-width: 200px; */
  border-radius: 5px;
}

/* {
  font-family: 'Hind', sans-serif!important;
 } */

 /* font-family: 'Hind', 'Arial', 'sans-serif' ,sans-serif;
    font-weight: normal;
    font-size: 15px;
    color: #333333; */

   tr:nth-child(even){background-color: #f2f2f2;}

td {
  
  color:rgb(0, 0, 0);
  font-family: 'Hind', 'Arial', 'sans-serif' ,sans-serif;
    font-weight: normal;
    font-size: 15px;
    color: #333333;
    text-align: center;

}

td:nth-child(3) {
  text-align: left;
}



h3{color: #0072bc!important;
  text-align: center;
}









 

td, th {
  border: 1px solid #999;
  padding: 0.5rem;
}

table {
  border-spacing: 0.5rem;
  position: absolute;
  top: 265px;
    left: 4px;
    right: 15px;
  

}




/* td, th {
  border: 0.5px solid blue;
  padding: 0.5rem;
  text-align: left;
} */

table {
  border-spacing: 0.5rem;
}
td {
  padding: 0.5rem;
}

/* td:nth-child(1) { background: hsl(150, 50%, 50%); }
td:nth-child(2) { background: hsl(160, 60%, 50%); }
td:nth-child(3) { background: hsl(170, 70%, 50%); }
td:nth-child(4) { background: hsl(180, 80%, 50%); }
td:nth-child(5) { background: hsl(190, 90%, 50%); }
td:nth-child(6) { background: hsl(200, 99%, 50%); } */

td:nth-child(3) {  width:250px;}
th:nth-child(3) {  text-align: center;}
td:nth-child(7) { text-align: center;background-color: #e6f5ff; }
td:nth-child(5) {  width:100px}
td:nth-child(6) {  width:50px}
td:nth-child(8) { text-align: center; }

td, th {
  width: 4rem;
  height: 2rem;
  border: 1px solid #ccc;
  
}
th {
  background:#0198b0;
  border-color: white;
}
body {
  padding: 1rem;
}

table {
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: gray
}

/* thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit
} */

/* tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit
} */

/* tfoot {
  display: table-footer-group;
  vertical-align: middle;
  border-color: inherit
} */

/* table > tr {
  vertical-align: middle;
}

col {
  display: table-column
}

colgroup {
  display: table-column-group
} */

/* tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit
} */

/* td, th {
  display: table-cell;
  vertical-align: inherit
} */

/* th {
  font-weight: bold
} */

/* caption {
  display: table-caption;
  text-align: -webkit-center
} */


/* body {
  font: normal medium/1.4 sans-serif;
} */
/* table {
  border-collapse: collapse;
  width: 100%;
} */
/* th, td {
  padding: 0.25rem;
  text-align: left;
  border: 1px solid #ccc;
} */




table {
  border-collapse: collapse;
  width: 100%;
  
}

tbody tr:hover {
  background:#e6f5ff;

}

.symbol{
  color:#5b5b5b;
}


html, body {
  height: 100%;
}

body {
  background-color: #fdfdfd;
}

.container {
  margin-top: 5em;
}

.search-field {
  border-radius: 3px !important;
  border-color: gray !important;
  box-shadow: 5px 5px 2px 1px rgb(0 0 0 / 20%) !important;
  position: absolute;
  left: 375px;
  top: 120px;
  width: 500px;
  padding: 12px 12px;
  margin: 7px;
  font-size: 17px;
  transition: background-color 0.3s ease-in-out;

  
  
  /* background-image: url(./download.png);  
  background-position: 1px 1px; 
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px; */

  transition: background-color 0.3s ease-in-out;

}
.search-field:focus {
  background-color:#daecfb;
  
}

a{
  color: #74e000;
  cursor: pointer;
}
button{

  padding: 6px 7px;
  
  color: #ffffff!important;
 
  border-radius: 6px;
  text-decoration: none;
  
}

button:hover {
  box-shadow: 0 5px 8px 0 rgba(0,0,0,0.24);
  transition: box-shadow 0.18s ease-in-out;
}

.girth{
  background: #74e000!important;
  position:absolute;
  top: 208px;
  left: 152px;

  padding: 6px 7px;
  
  color: #ffffff!important;
 
  border-radius: 6px;
  text-decoration: none;
  
}

button:hover {
  box-shadow: 0 5px 8px 0 rgba(0,0,0,0.24);
  transition: box-shadow 0.18s ease-in-out;
}

.girth{
  background: #74e000!important;
  position:absolute;
  top: 208px;
  left: 152px;}

.point{
  background: #74e000!important;
  position:absolute;
 
  top: 208px;
  left: 210px;
}

.on_surface_length{
  background: #71db00!important;
  position:absolute;
  top: 208px;
  left: 270px;
}

.Contoured{
  background: #71db00cc!important;
  position:absolute;
 
  top: 208px;
  left: 451px;

}

.Tape{
  background:#71db00cc!important;
  position:absolute;
 
  top: 208px;
  left: 542px;
}

.Arms{
  background: #71db009b!important;
  position:absolute;
 
  top: 208px;
  left: 643px;
}

.Foot{
  background: #71db009b!important;
  position:absolute;
 
  top: 208px;
  left: 702px;
}


.Head{position:absolute;
  background: #71db009b!important;
  top: 208px;
  left: 757px;

}

.LowerBody{position:absolute;
  background: #71db009b!important;
  top: 208px;
  left: 818px;
}

.Neck{position:absolute;
  background: #71db009b!important;
  top: 208px;
  left: 917px;
}

.Torso{position:absolute;
  background: #71db009b!important;
  top: 208px;
  left: 975px;
}

.Torso_Arms{position:absolute;
  background: #71db009b!important;
  top: 208px;
  left: 1036px;
}



/* 

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip 
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
} 
*/